html {
  max-width: 100%;
}

body.noScroll {
  overflow: hidden;
}

.roundedImage {
  border-radius: 0.25rem;
}

.MuiTooltip-tooltip {
  background-color: #63666a !important;
  font-family: Avenir !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 14px !important;
  color: #fff !important;
}

.MuiTooltip-tooltipPlacementTop {
  margin: 8px 0;
}

#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  z-index: 9000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: #c8102e;
}

#nprogress .peg {
  position: absolute;
  right: 0px;
  display: block;
  opacity: 1;
  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #c8102e, 0 0 5px #c8102e;
}

/* RS cart link */
.rsCartLinkAndItemCountWrapper {
  position: relative;
}

.rsCartLinkAndItemCountWrapper .rsCartLink {
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100%;
  height: 100%;
  padding: 16px;
  font-size: 22px;
  color: #000;
}

.rsCartLinkAndItemCountWrapper .rsCartCount {
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 10;
  padding: 0 5px;
  background: #c8102e;
  border-radius: 100px;
  font-size: 12px;
  font-weight: 800;
  color: #fff;
}

/* RS popover */
.rsPopoverBody {
  font-family: Avenir;
  font-size: 16px;
  color: #000000;
}

.rsPopoverBody .rsGetItBy {
  margin-left: 4px;
  margin-right: 4px;
  font-weight: 800;
}

.rsPopoverBody .rsOrderWithin {
  margin-left: 4px;
  font-weight: 800;
}

.rsPopoverBody .rsFasterShippingCost {
  margin-left: 4px;
  margin-right: 4px;
  font-weight: 800;
}

/* RS product offer */
.rsProductOfferContainer {
  font-family: Avenir;
  font-size: 16px;
  color: #000000;
}

.rsProductOfferContainer .rsPrice {
  font-size: 16px;
  font-weight: 800;
  color: #000000;
}

@media (min-width: 64rem) {
  .rsProductOfferContainer .rsPrice {
    font-size: 23px;
  }
}

.rsProductOfferContainer .rsReferencePrice {
  font-size: 16px;
  font-weight: 800;
  text-decoration: line-through;
  color: #c8102e;
}

.rsProductOfferContainer .rsReferencePriceMessage {
  font-size: 16px;
  font-weight: 700;
  color: #00cb51;
}

.rsProductOfferContainer .rsGetItBy,
.rsProductOfferContainer .rsOrderWithin {
  margin-right: 4px;
  font-weight: 800;
}

.rsProductOfferContainer .rsFreeNDayShippingWrapper {
  margin-top: 8px;
  margin-bottom: 4px;
  font-weight: 800;
}

.rsProductOfferContainer .rsShippingEstimateWrapper,
.rsProductOfferContainer .rsFasterShippingWrapper,
.rsProductOfferContainer .rsFasterShippingOptionTemplate {
  margin-bottom: 4px;
}

.rsProductOfferContainer .rsZipCodeWrapper {
  display: block;
  margin-bottom: 8px;
}

.rsProductOfferContainer .rsFasterShippingLabel {
  text-decoration: underline;
  cursor: pointer;
}

.rsProductOfferContainer .rsAddToCartForm input[type="submit"] {
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100%;
  height: 70px;
  margin: 1rem 0 6px;
  background-color: #000000;
  border: solid 1px #000000;
  border-radius: 6px;
  cursor: pointer;
  font-family: Avenir;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.07em;
  color: #ffffff;
}

.rsProductOfferContainer .rsAddToCartForm input[type="submit"]:hover {
  background-color: #333333;
}

/* RS zip code modal and form */
.rsZipCodeModalWrapper {
  font-family: Avenir;
  font-size: 16px;
}

.rsZipCodeModalWrapper .rsZipCodeModalForm {
  display: flex;
  padding: 16px !important;
}

.rsZipCodeModalWrapper .rsZipCodeModalInput {
  flex: 1 0 auto;
  padding: 8px 12px;
  border: solid 1px rgba(177, 181, 184, 0.5);
}

.rsZipCodeModalWrapper .rsZipCodeModalSubmit {
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  display: flex;
  padding: 8px 12px;
  border: solid 1px #000000;
  background-color: #000000;
  cursor: pointer;
  font-family: Avenir;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.07em;
  text-transform: capitalize;
  color: #ffffff;
}

/* RS mini cart */
.rsMiniCartModalWrapper .rsModal {
  width: 320px;
  margin-left: 0px;
  font-family: Avenir;
  font-size: 16px;
  text-decoration: none;
  color: #000000;
}

@media (min-width: 64rem) {
  .rsMiniCartModalWrapper .rsModal {
    width: 420px;
  }
}

.rsMiniCartModalWrapper .rsModalSubheader {
  padding: 16px 24px 12px !important;
}

.rsMiniCartModalWrapper .rsCheckIcon {
  color: #00cb51 !important;
}

.rsMiniCartModalWrapper .rsModalCloseX {
  top: 8px !important;
  right: 20px !important;
  font-size: 25px !important;
  font-weight: 400;
  color: #000000 !important;
}

.rsMiniCartModalWrapper .rsModalTitle {
  font-weight: 800;
}

.rsMiniCartModalWrapper .rsModal .rsModalBody {
  padding: 24px 24px 16px 24px !important;
}

.rsMiniCartModalWrapper .rsModal .rsModalBody .rsMiniCartContainer {
  flex-direction: column;
  display: flex;
  margin-bottom: 24px !important;
}

.rsMiniCartModalWrapper .rsModal .rsModalBody .rsMiniCartSummaryContainer {
  justify-content: space-around;
  flex-direction: column;
  display: flex;
  width: 100%;
}

.rsMiniCartModalWrapper .rsProductInfoContainer {
  padding: 0;
  border: none !important;
}

.rsMiniCartModalWrapper .rsProductInfoContainer {
  width: 100%;
}

.rsMiniCartModalWrapper .rsProductImageWrapper {
  position: relative;
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100%;
  height: auto;
  max-width: 128px;
  max-height: 128px;
  padding: 0;
  border: solid 1px rgba(0, 28, 65, 0.05);
  border-radius: 3px;
  overflow: hidden;
}

.rsMiniCartModalWrapper .rsProductImageWrapper:before {
  display: block;
  padding-top: 100%;
  content: "";
}

.rsMiniCartModalWrapper .rsProductImageWrapper .rsProductImage {
  position: absolute;
  flex-basis: 0;
  flex-grow: 1;
  width: auto;
  height: auto;
  max-width: 100%;
  margin: auto;
  padding-left: 10px;
  padding-right: 10px;
  vertical-align: middle;
}

.rsMiniCartModalWrapper .rsProductInfo {
  flex-direction: column;
  display: flex;
  padding-left: 1rem;
}

.rsMiniCartModalWrapper .rsProductTitle {
  height: initial !important;
  width: 100% !important;
  font-weight: 800 !important;
}

.rsMiniCartModalWrapper .rsProductInfoFooter {
  justify-content: flex-start;
  display: flex;
  width: 100%;
}

.rsMiniCartModalWrapper .rsMiniCartSummary {
  justify-content: space-between;
  display: flex;
  margin: 2rem 0;
}

.rsMiniCartSummaryContainer .rsCartIcon {
  font-size: 16px;
  margin-right: 4px;
}

.rsMiniCartSummaryContainer .rsCartCount {
  padding-right: 4px;
}

.rsMiniCartSummaryContainer .rsCartSubtotal {
  padding-left: 4px;
}

.rsMiniCartSummaryContainer .rsModalButton {
  align-items: center;
  display: flex;
  width: 100%;
  height: 48px;
  margin: 0.5rem;
  cursor: pointer;
}

.rsMiniCartSummaryContainer .rsModalCloseButton {
  justify-content: space-around !important;
  margin: 0px 0px 8px !important;
  padding: 6px !important;
  transition: 0.3s !important;
  background: #fff !important;
  border: solid 1px #000000 !important;
  border-radius: 3px !important;
  font-family: Avenir;
  font-size: 16px !important;
  font-weight: 800 !important;
  text-align: center !important;
  text-decoration: none !important;
  text-transform: capitalize;
  color: #000000 !important;
}

.rsMiniCartSummaryContainer .rsViewCartButton {
  justify-content: space-around !important;
  margin: 0px 0px !important;
  padding: 6px !important;
  transition: 0.3s !important;
  background: #000000 !important;
  border: solid 1px #000000 !important;
  border-radius: 3px !important;
  font-family: Avenir;
  font-size: 16px !important;
  font-weight: 800 !important;
  text-align: center !important;
  text-decoration: none !important;
  text-transform: capitalize;
  color: #ffffff !important;
}

.rsProductOfferContainer .rsAffirmProductMessage {
  margin: 6px 0px;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-size: 16px;
  color: #333;
}

.rsProductOfferContainer .rsAffirmProductMessage .__affirm-logo + a {
  display: block;
}

a.affirm-modal-trigger {
  color: #333;
  text-decoration: underline;
}

a.affirm-modal-trigger:hover {
  color: #000;
}
